







import { Component, Vue } from 'vue-property-decorator';
import CarPopupCategory from '@/modules/cars/components/car-popup-category.vue';

@Component({
    components: { CarPopupCategory },
})
export default class DayRateCategoryPage extends Vue {
}
