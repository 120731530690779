















































































































































































import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PercentFilter from '@/modules/common/filters/percent.filter';
import Day from '@/modules/common/types/day.type';
import Percent from '@/modules/common/types/percent.type';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import DayChanger from '@/modules/common/components/day-changer.vue';
import Currency from '@/modules/common/components/currency.vue';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import Occupancy from '@/modules/common/components/ui-kit/occupancy.vue';
import MedianComparisonTooltip from '@/modules/cars/components/median-comparison-tooltip.vue';
import CarFleetAvailabilityTooltip from '@/modules/cars/components/car-fleet-availability-tooltip.vue';
import CarTogglePrices from '@/modules/cars/components/car-toggle-prices.vue';
import CarPriceTable from '@/modules/cars/components/car-price-table.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import _ from 'lodash';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars-filters.service';
import CarsSharedService, { CarsSharedServiceS } from '../cars-shared.service';
import CarRatesDayScanBtn from './car-rates-day-scan-btn.vue';
import { BROKER_TO_BRAND, BROKER_TO_BROKER } from '../constants/car-filter-types.constant';
import CarsAnalysisService, { CarsAnalysisServiceS } from '../cars-analysis.service';

export interface TableData {
    price: number | null,
    company: string | null,
    carBrand: string | null,
    isAvailable?: boolean,
    screenshot: string | null,
    isMyCar: boolean,
    isMedian: boolean,
    sippCode?: string | null,
    shopDate?: Date | null,
    rectangleUniqueness?: string | null,
    rank?: number | null,
    originCompany?: string | null,
}

@Component({
    components: {
        DayChanger,
        ModalWrapper,
        Currency,
        PopupEventsContainer,
        CompsetScale,
        LoaderWrapper,
        Occupancy,
        CarFleetAvailabilityTooltip,
        MedianComparisonTooltip,
        CarPriceTable,
        CarTogglePrices,
        CarRatesDayScanBtn,
    },
    filters: { PercentFilter, MIPriceFilter },
})
export default class CarPopupCategory extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsAnalysisServiceS) carsAnalysisService!: CarsAnalysisService;

    medianComparisonTooltip: boolean = false;

    modes: any = { Brand: BROKER_TO_BRAND, Broker: BROKER_TO_BROKER };

    protected ownFiltersReady: boolean = false;
    protected alertMessage: string = '';
    protected sessionAlertDate: string = '';

    created() {
        // CarsAlertItem can pass sessionData in the router params, we need to reload the main data
        const { alertSessionData } = this.carsSharedService;
        if (alertSessionData) {
            this.carsSharedService.updateFiltersFromSessionStorageIfExist(alertSessionData);
            this.alertMessage = alertSessionData.alertMessage;
            this.sessionAlertDate = alertSessionData.alertDate;
        }
        this.ownFiltersReady = true;
    }

    get currentDataSource() {
        const { isBrokerMode } = this.carsService;
        const { currentChainCode } = this.carsFiltersService;
        const { dataSource, currentDataSourceMode, currentBrandAsBroker } = this.carsService.storeState.settings;
        if (isBrokerMode) {
            const mode = Object.keys(this.modes).find(modeKey => this.modes[modeKey] === currentDataSourceMode);
            const [, brand = 'All'] = currentBrandAsBroker.split(',');
            return `${mode}/${currentChainCode} ${brand}`;
        }
        return dataSource;
    }

    get carClasses() {
        return `${this.firstCategory} - ${this.secondCategory}`;
    }

    get fuelTypes() {
        const { carFuelTypes } = this.carsService.storeState.settings;
        if (carFuelTypes) {
            const firstType = carFuelTypes[0] || '';
            return (carFuelTypes.length > 1) ? `${firstType}...` : `${firstType}`;
        }
        return '';
    }

    get mileage() {
        return this.carsService.storeState.settings.mileage;
    }

    get paymentTerms() {
        return this.carsService.storeState.settings.paymentTerms;
    }

    get filtersReady() {
        return this.ownFiltersReady && this.carsFiltersService.storeState.filtersReady;
    }

    get title() {
        return this.carsFiltersService.selectedLocationName;
    }

    get documents() {
        return !!this.carsService.storeState.document;
    }

    getCompsetDataType(cat: string) {
        if (this.carsService) {
            return {
                isNA: this.getIsNA(cat),
                isSoldOut: this.isSoldOut(cat),
                isNoData: false,
            };
        }
        return {};
    }

    get priceType() {
        return this.carsService.storeState.settings.priceType;
    }

    getOccupancyTitle(cat: string) {
        const occupancyLevel = this.getOccupancyLevel(cat);
        if (occupancyLevel === null) {
            return 'N/A';
        }

        if (occupancyLevel === 0) {
            return 0;
        }

        return `${this.getCountCars(cat)} out of ${this.getTotalCars(cat)} (${occupancyLevel}%)`;
    }

    getOccupancyLevel(cat: string) {
        return this.carsService.occupancy(this.day, { selectedCarClass: cat });
    }

    getCountCars(cat: string) {
        const { countCars } = this.carsService.fleetAvailability(this.day, { selectedCarClass: cat });
        return countCars;
    }

    getTotalCars(cat: string) {
        const { totalCars } = this.carsService.fleetAvailability(this.day, { selectedCarClass: cat });
        return totalCars;
    }

    get day(): Day {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    get firstCategory(): string {
        return String(this.$route.params.firstCategory);
    }

    get secondCategory(): string {
        return String(this.$route.params.secondCategory);
    }

    get id(): string | null {
        const currentCar = this.carsService.currentCar(this.day) as CarsDocumentItemModel;
        if (currentCar) {
            return currentCar.identifier;
        }

        const competitorCars = this.carsService.competitorCars(this.day) as {
            [company: string]: CarsDocumentItemModel
        };
        if (competitorCars) {
            const competitorCarsKey = Object.keys(competitorCars);

            if (competitorCarsKey.length) {
                return competitorCars[competitorCarsKey[0]].identifier;
            }
        }

        return null;
    }

    getIsNA(cat: string) {
        return this.carsAnalysisService.allCarsForSelectedCarClass(this.day, [cat]) === false;
    }

    isSoldOut(cat: string) {
        return this.carsAnalysisService.allCarsForSelectedCarClass(this.day, [cat]) === null;
    }

    getCardColorClass(cat: string) {
        const percent = this.getPercent(cat);
        if (!percent) {
            return { green: percent === 0 };
        }

        const assessment = this.carsService.getCardAssessment(percent);

        return {
            green: assessment === ASSESSMENTS_TYPES.GOOD,
            yellow: assessment === ASSESSMENTS_TYPES.NORMAL,
            red: assessment === ASSESSMENTS_TYPES.BAD || this.isCompetitorsSoldOut(this.day),
        };
    }

    isCompetitorsSoldOut(day: Day) {
        return this.carsService.isCompetitorsSoldOut(day);
    }

    getPercent(cat: string): Percent | null {
        return this.carsAnalysisService.competitorPercentBySelectedCarClass(this.day, [cat]);
    }

    get shopDate() {
        const date = this.carsService.calculateShopDate(this.day);

        if (!date) {
            return null;
        }

        const day = (`0${date.getUTCDate()}`).slice(-2);
        const month = (`0${date.getUTCMonth() + 1}`).slice(-2);
        const year = date.getUTCFullYear();
        const hours = (`0${date.getUTCHours()}`).slice(-2);
        const minutes = (`0${date.getUTCMinutes()}`).slice(-2);

        return `${day}/${month}/${year} ${hours}:${minutes} UTC`;
    }

    get isOutOfRange() {
        const cars = this.carsService.allCars(this.day);
        return cars === null;
    }

    get showFleetIndicator() {
        const { branches } = this.carsSharedService;
        if (!branches) {
            return true;
        }
        const { chain } = this.carsService.storeState.settings;

        return !(chain && chain.chainId);
    }

    get isAvgPrice() {
        return this.carsService.storeState.settings.isAvgPrice;
    }

    async openPriceHistory(category: string) {
        await this.$router.push({
            name: `${this.$route.name}.price-history-popup`,
            params: { historyDay: `${this.day}`, category },
        });
    }

    get isAvailability() {
        return this.carsSharedService.isAvailability;
    }

    get hasAlertMessage() {
        return !!this.alertMessage;
    }

    get hasAlertDate() {
        return !!this.sessionAlertDate;
    }

    get alertDate() {
        const date = new Date(this.sessionAlertDate);

        if (!date) {
            return null;
        }

        const day = (`0${date.getUTCDate()}`).slice(-2);
        const month = (`0${date.getUTCMonth() + 1}`).slice(-2);
        const year = date.getUTCFullYear();
        const hours = (`0${date.getUTCHours()}`).slice(-2);
        const minutes = (`0${date.getUTCMinutes()}`).slice(-2);

        return `${day}/${month}/${year} ${hours}:${minutes} UTC`;
    }

    get priceHeightStyle() {
        return `height: calc(100% - ${this.hasAlertMessage ? '200px' : '130px'})`;
    }

    close() {
        sessionStorage.removeItem('sessionData');
        this.$router.push(this.$route.fullPath.replace(/\/day-popup.*/, ''));
    }
}
